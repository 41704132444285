import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export { default as NavbarPolisku } from "./components/Navbar";
export { default as FooterPolisku } from "./components/Footer";
export { default as LanguagePolisku } from "./components/Language";

export { AuthProvider, useAuth } from "./hooks/useAuth.js";
export { useAxios } from "./hooks/useAxios.js";
export { aesDecrypt, aesEncrypt } from "./hooks/useLocalStorage.js";

export const { bootstrap, mount, unmount } = lifecycles;
