import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { useAuth } from "../../hooks/useAuth";
import Language from "../../components/Language";
import { aesDecrypt, aesEncrypt } from "../../hooks/useLocalStorage";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

import ContactImg from "../../assets/images/contact-img.jpg";
import ICircle from "../../assets/Icon/i-circle.svg";
import Email from "../../assets/Icon/email.svg";
import Phone from "../../assets/Icon/phone.svg";
import Face from "../../assets/Icon/face.svg";

import { EKYC_COMMON_HOST } from "../../root.component";

function Contact() {
  const language = window.localStorage.getItem("language") || "id";
  const { echo } = Language();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { state } = useLocation();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const userEkyc = JSON.parse(
    aesDecrypt(window.localStorage.getItem("userEkyc"))
  );
  const action = JSON.parse(
    aesDecrypt(window.localStorage.getItem("isAction"))
  );

  const [isUpdateContact, setIsUpdateContact] = useState(
    !userEkyc?.email ||
      !userEkyc?.phone ||
      (state?.isAction &&
        (!user?.session?.client_data?.email ||
          !user?.session?.client_data?.phone))
      ? state?.action !== "reset-pin"
        ? true
        : false
      : false
  );

  const [withdrawalForm] = useState(
    JSON.parse(aesDecrypt(window.localStorage.getItem("withdrawalForm"))) || {}
  );
  const [email, setEmail] = useState(
    state?.isAction ? user?.session?.client_data?.email || user?.email : ""
  );
  const [phone, setPhone] = useState(
    state?.isAction ? user?.session?.client_data?.phone || user?.phone : ""
  );
  const [selectedActionOtp, setSelectedActionOtp] = useState(null);

  const handleUpdateContact = () => {
    setIsUpdateContact(!isUpdateContact);
  };

  const handleLanjut = () => {
    if (isUpdateContact) {
      useAxios({
        method: "post",
        override: {
          title: "ekyc-update-contact",
          url: `${EKYC_COMMON_HOST}/updatePhoneEmail`,
          token: userEkyc?.token,
          onboardId: `onboard_ekyc_${userEkyc?.onBoardId}`,
          body: { email, phone },
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              userEkyc.step = "verification-result";
              userEkyc.email = email;
              userEkyc.phone = phone;
              window.localStorage.setItem(
                "userEkyc",
                aesEncrypt(JSON.stringify(userEkyc))
              );
              navigate("/verification-result", {
                state: {
                  _isUpdateContact: true,
                },
              });
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc.Indonesian
                  : data.resp_desc.English;
              alert(responseError || "Terjadi kesalahan.");
            }
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Terjadi kesalahan.");
          }
        }
      });
    } else {
      useAxios({
        method: "post",
        override: {
          title: "ekyc-send-email-otp",
          url: `${EKYC_COMMON_HOST}/otp`,
          token: userEkyc?.token,
          onboardId: `onboard_ekyc_${userEkyc?.onBoardId}`,
          body: { type: "email" },
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              userEkyc.step = "otp";
              window.localStorage.setItem(
                "userEkyc",
                aesEncrypt(JSON.stringify(userEkyc))
              );
              navigate("/otp", {
                state: {
                  type: "email",
                },
              });
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc.Indonesian
                  : data.resp_desc.English;
              alert(responseError || "Terjadi kesalahan.");
              if (data.resp_code === 4) {
                window.location.replace(userEkyc?.redirect_url);
              }
            }
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Terjadi kesalahan.");
          }
        }
      });
    }
  };

  const handleAction = () => {
    if (state?.isAction && state?.action === "reset-pin") {
      let method = "";
      if (selectedActionOtp === "face") {
        method = "forgot_pin_face";
      } else if (selectedActionOtp === "email") {
        method = "forgot_pin_email_otp";
      } else {
        method = "forgot_pin_phone_otp";
      }

      useAxios({
        method: "post",
        url: "/authenticate",
        body: {
          method,
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              if (method === "forgot_pin_face") {
                if (data?.liveness_url)
                  window.location.href = data?.liveness_url;
              } else {
                navigate("/otp", {
                  state: {
                    type: selectedActionOtp,
                    method: method,
                    isAction: true,
                  },
                });
              }
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.Indonesian
                  : data.resp_desc?.English;
              alert(responseError || "Terjadi kesalahan.");
            }
          } else {
            if (status === 401) {
              alert(echo("session-expired"));
              logout();
              navigate("/login");
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.Indonesian
                  : data.resp_desc?.English;
              alert(responseError || echo("something-went-wrong"));
            }
          }
        }
      });
      return;
    }
    if (selectedActionOtp === "face") {
      useAxios({
        method: "post",
        url: "/authenticate",
        body: {
          method: "face",
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              if (data?.liveness_url)
                window.location.replace(data?.liveness_url);
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.Indonesian
                  : data.resp_desc?.English;
              alert(responseError || "Terjadi kesalahan.");
            }
          } else {
            if (status === 401) {
              alert(echo("session-expired"));
              logout();
              navigate("/login");
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.Indonesian
                  : data.resp_desc?.English;
              alert(responseError || echo("something-went-wrong"));
            }
          }
        }
      });
      return;
    }
    if (withdrawalForm?.chosenPolicy?.currency === "IDR") {
      if (withdrawalForm?.nominalPenarikan >= 25000000) {
        alert(echo("nomial-withdrawal-with-25mio-more"));
        return;
      }
    } else {
      if (withdrawalForm?.nominalPenarikan >= 2500) {
        alert(echo("nomial-withdrawal-with-25mio-more"));
        return;
      }
    }
    const bodyOtp = {};
    if (selectedActionOtp === "email") {
      bodyOtp.method = "email_otp";
    } else {
      bodyOtp.method = "phone_otp";
    }
    useAxios({
      method: "post",
      url: "/authenticate",
      body: bodyOtp,
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          if (data.resp_code == 0) {
            navigate("/otp", {
              state: {
                type: selectedActionOtp,
                method: bodyOtp?.method,
                isAction: true,
              },
            });
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Terjadi kesalahan.");
          }
        } else {
          if (status === 401) {
            alert(echo("session-expired"));
            logout();
            navigate("/login");
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.Indonesian
                : data.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
          }
        }
      }
    });
  };
  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#f7f7f7",
        paddingBottom: "50px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: "#e5eaef",
          color: "#000",
          padding: "24px 60px 38px 24px",
          font: "700 36px/48px Open Sans, sans-serif",
          textAlign: "start",
          paddingTop: "",
          paddingBottom: "",
          marginRight: 0,
          marginLeft: 0,
          [theme.breakpoints.up("lg")]: {
            textAlign: "center",
            paddingTop: "60px",
            paddingBottom: "60px",
            marginRight: "40px",
            marginLeft: "40px",
          },
        })}
      >
        {isUpdateContact || state?.isAction || state?.action ? (
          <>
            {state?.isAction || state?.action
              ? echo("otp-verification")
              : echo("provide-your")}
            <Typography
              sx={{
                font: "700 36px/48px Open Sans, sans-serif",
                fontWeight: 300,
              }}
            >
              {state?.isAction || state?.action
                ? echo("apply/transaction")
                : echo("contact-information")}
            </Typography>
          </>
        ) : (
          <>
            {echo("please-check-your")}
            <Typography
              sx={{
                font: "700 36px/48px Open Sans, sans-serif",
                fontWeight: 300,
              }}
            >
              {echo("contact-information")}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          [theme.breakpoints.up("lg")]: {
            flex: 3,
            margin: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          },
        })}
      >
        <ContactDiv>
          <Typography
            sx={(theme) => ({
              fontWeight: 700,
              fontSize: "18px",
              textAlign: "flex-start",
            })}
          >
            {echo("Contact-Information")}
          </Typography>
          <ContactDescription>
            {state?.isAction
              ? echo("select-otp-method")
              : echo("your-email-and-phone-number-are-required")}
            {!isUpdateContact && !(state?.isAction || state?.action) && (
              <>
                <br />
                <br />
                {echo("if-there-is-change")}
              </>
            )}
          </ContactDescription>
          {isUpdateContact && !(state?.isAction || state?.action) && (
            <ContactInstruction>
              {echo("enter-your-new-email-and-phone-number")}
            </ContactInstruction>
          )}
          <ContactBox
            onClick={() => {
              setSelectedActionOtp("email");
            }}
            style={{
              border:
                selectedActionOtp === "email" && state?.isAction
                  ? "1px solid #ed1a2d"
                  : "",
            }}
          >
            <IconEmail loading="lazy" src={Email} />
            {isUpdateContact && !(state?.isAction || state?.action) ? (
              <input
                style={{
                  textOverflow: "ellipsis",
                  fontFamily: "Open Sans, sans-serif",
                  justifyContent: "center",
                  flex: 1,
                  fontSize: "18px",
                  fontWeight: 300,
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  border: 0,
                  outline: "none",
                  lineHeight: "156%",
                  width: "100%",
                }}
                placeholder={echo("email")}
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            ) : (
              <ContactText>
                {state?.action || state?.isAction
                  ? user?.email
                  : userEkyc?.email}
              </ContactText>
            )}
          </ContactBox>
          <ContactBox
            onClick={() => {
              setSelectedActionOtp("phone");
            }}
            style={{
              border:
                selectedActionOtp === "phone" && state?.isAction
                  ? "1px solid #ed1a2d"
                  : "",
            }}
          >
            <img loading="lazy" src={Phone} />
            {isUpdateContact && !(state?.isAction || state?.action) ? (
              <input
                style={{
                  textOverflow: "ellipsis",
                  fontFamily: "Open Sans, sans-serif",
                  justifyContent: "center",
                  flex: 1,
                  fontSize: "18px",
                  fontWeight: 300,
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  border: 0,
                  outline: "none",
                  lineHeight: "156%",
                  width: "100%",
                }}
                placeholder={echo("phone-number")}
                type="tel"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            ) : (
              <ContactText>
                {state?.action || state?.isAction
                  ? user?.phone
                  : userEkyc?.phone}
              </ContactText>
            )}
          </ContactBox>
          {state?.isAction && (
            <>
              <ContactBox
                onClick={() => {
                  setSelectedActionOtp("face");
                }}
                style={{
                  border:
                    selectedActionOtp === "face" && state?.isAction
                      ? "1px solid #ed1a2d"
                      : "",
                }}
              >
                <img loading="lazy" src={Face} />
                <ContactText>Using face recognition</ContactText>
              </ContactBox>
              <ContactAlert
                style={{
                  display: !selectedActionOtp ? "" : "none",
                }}
              >
                <InfoIcon loading="lazy" src={ICircle} />
                <ContactAlertText>
                  Pilih salah satu metode verifikasi
                </ContactAlertText>
              </ContactAlert>
            </>
          )}
          {state?.isAction ? (
            <>
              <ContactButton
                style={{
                  display: !selectedActionOtp ? "none" : "block",
                }}
                onClick={() => handleAction()}
              >
                {echo("continue")}
              </ContactButton>
            </>
          ) : (
            <>
              <ContactButton onClick={() => handleLanjut()}>
                {isUpdateContact ? echo("update-data") : echo("confirm")}
              </ContactButton>
              {!userEkyc?.email ||
                !userEkyc?.phone ||
                (!state?.isAction && (
                  <ButtonBottom onClick={handleUpdateContact}>
                    {isUpdateContact
                      ? echo("back")
                      : echo("update-contact-information")}
                  </ButtonBottom>
                ))}
            </>
          )}
        </ContactDiv>
        <Box
          sx={(theme) => ({
            display: {
              xs: "none",
              lg: "block",
            },
            flex: 1,
          })}
        >
          <img
            height="500px"
            width="666px"
            style={{
              width: "666px",
              height: "500px",
              objectFit: "cover",
            }}
            src={ContactImg}
          />
        </Box>
      </Box>
    </div>
  );
}

export default Contact;

const ContactDiv = styled.div`
  border-radius: 14px;
  display: flex;
  flex: 2;
  flex-direction: column;
  font-size: 18px;
  color: var(--Primary-Color-Black, #000);
  font-weight: 300;
  line-height: 156%;
  padding: 20px 24px;
`;

const ContactDescription = styled.p`
  margin-top: 0px;
  font: 400 16px/24px Open Sans, sans-serif;
`;

const ContactBox = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  margin-top: 20px;
  gap: 14px;
  white-space: nowrap;
  padding: 10px 24px;
`;

const IconEmail = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  margin: auto 0;
`;

const ContactText = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  font-family: Open Sans, sans-serif;
  justify-content: center;
  flex: 1;
  text-wrap: wrap;
`;

const ContactButton = styled.div`
  font-family: Open Sans, sans-serif;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  margin-top: 20px;
  color: var(--Primary-Color-White, #fff);
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 12px 22px;
  width: max-content;
  cursor: pointer;
`;

const ContactInstruction = styled.div`
  align-self: stretch;
  color: #000;
  max-width: 240px;
  font: 700 18px/28px Open Sans, sans-serif;
`;

const ButtonBottom = styled.div`
  color: var(--Primary-Black-primary, #000);
  text-align: left;
  font: 700 14px/171% Open Sans, sans-serif;
  margin-top: 40px;
  cursor: pointer;
`;

const ContactAlert = styled.p`
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: var(--Primary-Prudential-red, #ed1b2e);
  font-weight: 600;
  line-height: 24px;
`;

const InfoIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 14px;
  margin: auto 0;
`;

const ContactAlertText = styled.div`
  font-family: Open Sans, sans-serif;
`;

export function maskEmail(email) {
  if (!email) return email;
  const [username, domain] = email?.split("@");

  const maskedUsername = username[0] + "****" + username.slice(-1);

  const maskedEmail = maskedUsername + "@" + domain;

  return maskedEmail;
}

export function maskPhone(phone) {
  if (!phone) return phone;
  let digits = phone.replace(/\D/g, "");

  if (digits.startsWith("0")) {
    digits = "62" + digits.slice(1);
  } else if (!digits.startsWith("62")) {
    digits = "62" + digits;
  }

  const maskedPhone = `+62${digits.slice(2, 6)}****${digits.slice(-4)}`;
  return maskedPhone;
}
