import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import pruLogo from "../assets/Icon/pru-home-icon.png";
import pruNSyariahLogo from "../assets/SVGIcon/desktop_logo.png";
import { aesDecrypt } from "../hooks/useLocalStorage";
import Language from "./Language";

const Navbar = () => {
  const { echo } = Language();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const { logout } = useAuth();

  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setPathname(window.location.pathname);
    };

    window.addEventListener("popstate", handleLocationChange);
    window.addEventListener("pushstate", handleLocationChange);
    window.addEventListener("replacestate", handleLocationChange);

    return () => {
      window.removeEventListener("popstate", handleLocationChange);
      window.removeEventListener("pushstate", handleLocationChange);
      window.removeEventListener("replacestate", handleLocationChange);
    };
  }, []);

  const renderKeluarBtn = () => {
    if (pathname === "/login") return;
    if (pathname === "/otp") return;
    if (pathname === "/pin") return;
    if (pathname === "/create-pin") return;
    if (pathname === "/verification-result") return;
    if (pathname === "/contact") return;
    if (pathname.startsWith("/ekyc")) return;
    if (pathname.startsWith("/liveness")) return;
    if (pathname.startsWith("/terms-and-conditions")) return;
    return (
      <Typography
        sx={{
          fontWeight: "bold",
          color: "red",
          fontSize: "12px",
          textAlign: "right",
          cursor: "pointer",
        }}
        onClick={logout}
      >
        {echo("logout")}
      </Typography>
    );
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "white",
        boxShadow: "0 4px 24px -2px #D3d3d3",
      }}
      position="sticky"
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            if (user?.session) window.location.href = "/";
            else window.location.href = "/login";
          }}
        >
          <img
            style={{
              height: "30px",
              width: "180px",
            }}
            alt="pruHome"
            src={pruNSyariahLogo}
          />
        </Box>
        {renderKeluarBtn()}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
