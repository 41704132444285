import { useState } from "react";
import CryptoJS from "crypto-js";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return aesDecrypt(JSON.parse(value));
      } else {
        window.localStorage.setItem(
          keyName,
          aesEncrypt(JSON.stringify(defaultValue))
        );
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(
        keyName,
        !newValue ? newValue : aesEncrypt(JSON.stringify(newValue))
      );
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

var _secretKey = "letsbetheking";

export function aesEncrypt(str) {
  if (!str) return str;
  let enc = CryptoJS.AES.encrypt(str, _secretKey);
  return enc.toString();
}

export function aesDecrypt(str) {
  if (!str) return str;
  let decryptedBytes = CryptoJS.AES.decrypt(str, _secretKey);
  var decryptedMessage = decryptedBytes?.toString(CryptoJS.enc.Utf8);
  return decryptedMessage;
}
