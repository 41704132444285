import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import PIN from "./pages/login/PIN";

import { AuthProvider, useAuth } from "./hooks/useAuth";
import { aesDecrypt } from "./hooks/useLocalStorage";
import TNC from "./pages/login/TNC";
import Contact from "./pages/verification/Contact";
import Landing from "./pages/verification/Landing";
import OTP from "./pages/verification/OTP";
import Result from "./pages/verification/Result";

function ekycBaseUrl() {
  if (process.env.NODE_ENV === "production")
    return "https://polisku.prudential.co.id/ekyc-common";
  if (process.env.NODE_ENV === "development")
    return "https://polisku-uat.prudential.co.id/ekyc-common";
  return "https://polisku-uat.prudential.co.id/ekyc-common";
}

export const EKYC_COMMON_HOST = ekycBaseUrl();

function RequireAuth({ children }) {
  const { user } = useAuth();
  const location = useLocation();

  return user ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

function RequireSession({ children }) {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const location = useLocation();

  return user?.session ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

function RequireStep({ children, step = "" }) {
  const userEkycStorage = window.localStorage.getItem("userEkyc");

  const userEkyc =
    !userEkycStorage || userEkycStorage === "null"
      ? userEkycStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("userEkyc")));
  const location = useLocation();
  const isWithdrawal = JSON.parse(
    aesDecrypt(window.localStorage.getItem("withdrawalForm"))
  );
  const action = JSON.parse(aesDecrypt(window.localStorage.getItem("action")));

  return userEkyc?.step === step || isWithdrawal || action ? (
    children
  ) : (
    <Navigate to="/404" replace state={{ path: location.pathname }} />
  );
}

function RequireLogout({ children }) {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const location = useLocation();

  return !user?.policy ? (
    children
  ) : (
    <Navigate to="/" replace state={{ path: location.pathname }} />
  );
}

function NotFound() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1>404 Halaman Tidak Ditemukan</h1>
    </div>
  );
}

export default function Root(props) {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));

  return (
    <BrowserRouter>
      <AuthProvider userData={user}>
        <Navbar />
        <Routes>
          <Route
            path="/login"
            element={
              <RequireLogout>
                <Login />
              </RequireLogout>
            }
          />
          <Route
            path="/pin"
            element={
              <RequireAuth>
                <RequireLogout>
                  <PIN />
                </RequireLogout>
              </RequireAuth>
            }
          />
          <Route
            path="/create-pin"
            element={
              <RequireAuth>
                <RequireLogout>
                  <PIN />
                </RequireLogout>
              </RequireAuth>
            }
          />
          <Route
            path="/otp"
            element={
              <RequireStep step={"otp"}>
                <OTP />
              </RequireStep>
            }
          />
          <Route
            path="/contact"
            element={
              <RequireStep step={"update-contact"}>
                <Contact />
              </RequireStep>
            }
          />
          <Route
            path="/verification-result"
            element={
              <RequireStep step={"verification-result"}>
                <Result />
              </RequireStep>
            }
          />
          <Route
            path="/"
            element={
              <RequireAuth>
                <RequireSession>
                  <Home />
                </RequireSession>
              </RequireAuth>
            }
          />
          <Route path="/check-ekyc" element={<Landing />} />
          <Route path="/terms-and-conditions" element={<TNC />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  );
}
