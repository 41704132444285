import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { useAxios } from "../../hooks/useAxios";
import Language from "../../components/Language";
import { aesDecrypt, aesEncrypt } from "../../hooks/useLocalStorage";
import styled from "@emotion/styled";

import KeyImg from "../../assets/images/key-img.jpg";
import Success from "../../assets/SVGIcon/checkmark-circle-outline.svg";
import Failed from "../../assets/SVGIcon/close-circle-outline.svg";
import Warning from "../../assets/SVGIcon/warning-outline.svg";

import { EKYC_COMMON_HOST } from "../../root.component";

function Result() {
  const language = window.localStorage.getItem("language") || "id";
  const { echo } = Language();
  const { state } = useLocation();
  const navigate = useNavigate();
  const userEkyc = JSON.parse(
    aesDecrypt(window.localStorage.getItem("userEkyc"))
  );

  const renderIcon = () => {
    if (state?.KTPFace) {
      const objKTPFace = state?.KTPFace;
      if (objKTPFace?.isSuccess) return Success;
      if (objKTPFace?.isThirdFailed) return Failed;
      else return Warning;
    }
    if (state?._isUpdateContact) return Success;
    if (state?.isOTPSuccess) return Success;
    if (state?.isThirdFailed) return Failed;
    if (state?.isActionSuccess) return Success;
    return Failed;
  };

  const renderResultTitle = () => {
    if (state?.KTPFace) {
      const objKTPFace = state?.KTPFace;
      if (objKTPFace?.isSuccess) return echo("finish");
      if (objKTPFace?.isThirdFailed) return echo("verification-failed");
      else return echo("verification-not-yet-successful");
    }
    if (state?._isUpdateContact) return echo("contact-information-updated");
    if (state?.isOTPSuccess) return echo("finish");
    if (state?.isThirdFailed) return echo("verification-failed");
    if (state?.isActionSuccess) return echo("finish");
    return echo("verification-failed");
  };

  const renderResultDesc = () => {
    if (state?.KTPFace) {
      const objKTPFace = state?.KTPFace;
      if (objKTPFace?.isSuccess) return echo("verification-successful");
      if (objKTPFace?.isThirdFailed)
        return echo("sorry-your-verification-failed");
      else return echo("sorry-your-data-not-verified-yet");
    }
    if (state?._isUpdateContact) return echo("please-confirm-to-continue");
    if (state?.isOTPSuccess) return echo("verification-success-continue");
    if (
      state?.respDesc === "OTP tidak valid. Silakan request OTP baru" ||
      state?.respDesc === "OTP yang Anda masukkan salah. Silakan coba lagi" ||
      state?.respDesc === "OTP Kadaluwarsa. Silakan request OTP baru" ||
      state?.respDesc === "Permintaan OTP telah melewati batas harian"
    ) {
      return echo("otp-invalid-try-again");
    }
    if (state?.isThirdFailed) return echo("sorry-your-verification-failed");
    if (state?.isActionSuccess) return echo("verification-successful");
    return echo("sorry-your-data-not-verified-yet");
  };

  const renderButtonText = () => {
    if (state?.KTPFace) {
      const objKTPFace = state?.KTPFace;
      if (objKTPFace?.isSuccess) return echo("continue");
      if (objKTPFace?.isThirdFailed) return echo("back");
      return echo("try-again");
    }
    if (state?._isUpdateContact) return echo("continue");
    if (state?.isOTPSuccess) return echo("continue");
    if (state?.isThirdFailed) return echo("back");
    if (state?.isActionSuccess) return echo("continue");
    return echo("try-again");
  };

  const handleNext = () => {
    if (state?.KTPFace) {
      const objKTPFace = state?.KTPFace;
      if (objKTPFace?.isSuccess) {
        userEkyc.step = "update-contact";
        window.localStorage.setItem(
          "userEkyc",
          aesEncrypt(JSON.stringify(userEkyc))
        );
        navigate("/contact");
        return;
      }
      if (objKTPFace?.isThirdFailed) {
        window.location.replace(userEkyc?.redirect_url);
        window.localStorage.removeItem("userEkyc");
      }
      navigate("/ekyc");
      return;
    }
    if (state?._isUpdateContact) {
      useAxios({
        method: "post",
        override: {
          title: "ekyc-send-email-otp",
          url: `${EKYC_COMMON_HOST}/otp`,
          token: userEkyc?.token,
          onboardId: `onboard_ekyc_${userEkyc?.onBoardId}`,
          body: { type: "email", email: userEkyc?.email },
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              userEkyc.step = "otp";
              window.localStorage.setItem(
                "userEkyc",
                aesEncrypt(JSON.stringify(userEkyc))
              );
              navigate("/otp", {
                state: {
                  type: "email",
                  isUpdateContact: true,
                },
              });
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc.Indonesian
                  : data.resp_desc.English;
              alert(responseError || "Terjadi kesalahan.");
            }
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Terjadi kesalahan.");
          }
        }
      });
      return;
    }
    if (state?.isOTPSuccess) {
      window.location.replace(userEkyc?.redirect_url);
      window.localStorage.removeItem("userEkyc");
      return;
    }
    if (state?.isThirdFailed) {
      window.location.replace(userEkyc?.redirect_url);
      window.localStorage.removeItem("userEkyc");
      return;
    }
    if (state?.isActionSuccess) {
      window.localStorage.removeItem("withdrawalForm");
      navigate("/");
      return;
    }
    navigate("/ekyc");
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingTop: 6,
          gap: 5,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          marginLeft: 10,
          display: {
            xs: "none",
            lg: "block",
          },
        })}
      >
        <img
          height="400px"
          width="547px"
          style={{
            width: "547px",
            height: "400px",
            objectFit: "cover",
          }}
          src={KeyImg}
        />
      </Box>
      <VerificationFailedCardWrapper>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            [theme.breakpoints.up("lg")]: {
              justifyContent: "flex-start",
              alignItems: "flex-start",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
            })}
          >
            <VerificationFailedIcon
              style={{ alignSelf: "center" }}
              loading="lazy"
              src={renderIcon()}
              alt="Verification icon"
            />
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("lg")]: {
                display: "flex",
                justifyContent: "center",
              },
            })}
          >
            <VerificationFailedContent>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  [theme.breakpoints.up("lg")]: {
                    justifyContent: "flex-start",
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  <Img loading="lazy" src={renderIcon()} />
                </Box>
                <VerificationFailedTitle>
                  {renderResultTitle()}
                </VerificationFailedTitle>
              </Box>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: 2,
                  [theme.breakpoints.up("lg")]: {
                    justifyContent: "flex-start",
                    textAlign: "start",
                  },
                })}
              >
                <VerificationFailedDescription>
                  {renderResultDesc()}
                </VerificationFailedDescription>
              </Box>
            </VerificationFailedContent>
          </Box>
          <Button
            onClick={handleNext}
            sx={(theme) => ({
              justifyContent: "center",
              borderRadius: "30px",
              backgroundColor: "var(--Primary-Color-Prudential-Red, #ED1B2E)",
              color: "var(--Primary-Color-White, #FFF)",
              whiteSpace: "nowrap",
              textAlign: "center",
              padding: "12px 22px",
              font: "600 12px/156% Open Sans, sans-serif",
              border: "none",
              cursor: "pointer",
              width: 100,
              textTransform: "none",
              marginTop: "20px",
              alignSelf: "center",
              "&:hover": {
                backgroundColor: "#ed1a2d",
                boxShadow: "0 12px 8px -2px #D3d3d3",
              },
              [theme.breakpoints.up("lg")]: {
                alignSelf: "flex-start",
              },
            })}
          >
            {renderButtonText()}
          </Button>
        </Box>
      </VerificationFailedCardWrapper>
    </Box>
  );
}

const VerificationFailedCardWrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  text-align: start;
  border-radius: 14px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 40px;
`;

const VerificationFailedIcon = styled.img`
  width: 120px;
  max-width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;

const VerificationFailedContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: var(--Primary-Color-Black, #000);
`;

const VerificationFailedTitle = styled.div`
  text-align: start;
  font: 700 24px/32px Open Sans, sans-serif;
`;

const VerificationFailedDescription = styled.p`
  font: 300 20px/28px Open Sans, sans-serif;
`;

export default Result;
