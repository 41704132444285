import React from "react";

const TNC = () => {
  return (
    <div
      style={{
        height: "100%",
        padding: 40,
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
      }}
    >
      <h1
        style={{
          lineHeight: "32px",
        }}
      >
        Term and Condition & Inform Consent
      </h1>
      <h2
        style={{
          lineHeight: "32px",
        }}
      >
        KETENTUAN PENARIKAN DANA (WITHDRAWAL) POLIS PRULINK
      </h2>
      <h3>Ketentuan Umum:</h3>
      <ol>
        <li>Polis dalam status aktif (in force).</li>
        <li>
          Formulir elektronik dan persyaratan/kelengkapan dokumen yang
          diperlukan sudah harus diterima secara lengkap oleh Kantor Pusat
          Prudential Indonesia/Prudential Syariah sebelum tenggat waktu yang
          ditentukan, dan apabila permintaan Penarikan Dana (Withdrawal)
          tersebut disetujui, maka jumlah unit yang dibatalkan akan ditentukan
          berdasarkan Harga unit pada tanggal perhitungan ketika tenggat waktu
          ditentukan. Setelah suatu tenggat waktu maka jumlah unit yang
          dibatalkan ditentukan berdasarkan harga unit pada tanggal perhitungan
          terdekat setelah tanggal perhitungan tersebut. Apabila dokumen yang
          diterima tidak lengkap dan/atau tidak benar, maka perhitungan harga
          unit akan mengikuti harga unit setelah kelengkapan dokumen terakhir
          diterima oleh Kantor Pusat Prudential Indonesia/Prudential Syariah
          sesuai dengan ketentuan tenggat waktu yang berlaku. Tenggat waktu
          untuk Dana Investasi : Pengajuan dapat diproses di hari yg sama s/d
          jam 17.00 WIB.
        </li>
        <li>
          Apabila pengajuan Penarikan Dana (Withdrawal) yang diterima tidak
          lengkap atau Formulir Elektronik tidak terisi dengan lengkap dan
          benar, maka perhitungan Harga Unit akan mengikuti Harga Unit setelah
          kelengkapan dokumen terakhir diterima oleh Kantor Pusat Prudential
          Indonesia/Prudential Syariah sesuai dengan ketentuan tenggat waktu
          yang berlaku di Prudential Indonesia/Prudential Syariah.
        </li>
        <li>
          Dana dari hasil penarikan hanya dapat ditujukan ke rekening bank atas
          nama Pemegang Polis. Nama dan data rekening harus lengkap dan sama
          dengan data yang tercatat pada bank. Apabila terdapat perbedaan nama
          antara data Polis dan nama pada rekening bank, maka Pemegang Polis
          wajib melampirkan surat pernyataan dan dokumen pendukung (Akta
          Lahir/Kartu Keluarga/KTP/Paspor/Surat Penetapan Ganti Nama dari
          Pengadilan) mengenai perbedaan penulisan nama tersebut atau mengajukan
          perubahan/koreksi nama (jika diperlukan) dengan mengisi Formulir
          Perubahan data Pemegang Polis Non Syarah/Syariah (sesuai dengan jenis
          Polis).
        </li>
        <li>
          Apabila Polis dalam proses Penarikan Dana (Withdrawal) mengalami gagal
          transfer, maka dana tersebut akan dialokasikan sebagai Alokasi top up
          Otomatis jika memenuhi ketentuan.
        </li>
        <li>
          Dalam hal Penarikan Dana (Withdrawal) dari Polis ini digunakan untuk
          pembayaran Premi/Kontribusi Berkala, maka pembayaran tersebut hanya
          dapat ditujukan ke Polis lain atas nama Pemegang Polis,
          Tertanggung/Peserta (Yang Diasuransikan), Tertanggung/ Peserta (Yang
          Diasuransikan) Tambahan 1 , Tertanggung/ Peserta (Yang Diasuransikan)
          Tambahan 2 dan Penerima Manfaat dari Polis atas nama Pemegang Polis
          atau keluarga inti (Suami/Istri/Anak/Orang Tua) dari Pemegang Polis
          dengan melampirkan dokumen pendukung (Kartu Keluarga/Akte lahir).
        </li>
        <li>
          Dana dari hasil penarikan yang digunakan untuk Premi/Kontribusi
          dibayar di muka wajib menuliskan periode pembayaran Premi/Kontribusi
          tersebut dengan masa berlaku pengajuan Premi/Kontribusi dibayar di
          muka maksimal 4 (empat) tahun sejak tanggal pengajuan. Apabila dana
          penarikan digunakan untuk Premi/Kontribusi dibayar di muka dengan masa
          berlaku lebih dari 4 (empat) tahun, maka pengajuan penarikan dana
          tidak dapat diproses dan Pemegang Polis dapat mengajukan Formulir baru
          beserta kelengkapan dokumen pendukung. Untuk Polis yang mengalami
          kenaikan Premi/Kontribusi setiap tahun, Premi/Kontribusi dibayar
          dimuka dapat diajukan untuk masa berlaku maksimal 1 (satu) tahun
          sampai ulang tahun Polis terdekat berikutnya. Ketentuan
          Premi/Kontribusi dibayar di muka akan mengacu pada Formulir
          Premi/Kontribusi Dibayar di Muka.
        </li>
        <li>
          Pajak yang dikenakan atas Penarikan Polis adalah sesuai dengan
          ketentuan perundang-undangan pajak yang berlaku, dan/atau setiap
          perubahannya sebagaimana dapat ditentukan oleh Pemerintah Republik
          Indonesia dari waktu ke waktu.
        </li>
        <li>
          Pembebanan Biaya/Ujrah penarikan (jika ada) untuk masing-masing produk
          akan mengikuti ketentuan yang ada pada Polis.
        </li>
        <li>
          Minimum penarikan untuk PRULink:
          <ul>
            <li>
              Minimum penarikan PRULink Investor Account (Polis terbit sebelum
              01/10/2007) : Rp1.000.000/USD250 dan sisa Nilai Tunai saat
              penarikan dilakukan adalah Rp5.000.000/USD1.500.
            </li>
            <li>
              Minimum penarikan PRULink Investor Account (Polis terbit mulai
              01/10/2007) : Rp1.000.000/USD250 dan sisa Nilai Tunai saat
              penarikan dilakukan adalah Rp12.000.000/USD1.500.
            </li>
            <li>
              Minimum penarikan VERSALink Investor Account, VERSALink Investor
              Account Plus, BUILDERInvestor Account Max, PRULink Capital
              Account, PRUInvestor Optima Account dan PRUInvestor Ultima Account
              : Rp1.000.000/USD100 dan sisa Nilai Tunai saat penarikan dilakukan
              adalah Rp12.000.000/USD1.500.
            </li>
            <li>
              Minimum penarikan IMPERIA, FORTA PLUS, PRULink Prime Account,
              VERSALink Maxima Account,BUILDERAssurance Account Max, PRUWealth
              Maxima Account dan PRUWealth Prime Account : Rp1.000.000/USD250
              dan sisa Nilai Tunai saat penarikan dilakukan adalah
              Rp1.000.000/USD250.
            </li>
            <li>
              Minimum penarikan PRULink Assurance Account Rp500.000/USD100 dan
              sisa Nilai Tunai saat penarikan dilakukan adalah
              Rp1.000.000/USD250.
            </li>
            <li>
              Minimum penarikan PRULink Fixed Pay, PRULink Edu Protection dan
              PRUMy Child: Rp500.000 dan sisa Nilai Tunai saat penarikan
              dilakukan adalah Rp1.000.000. Khusus untuk PRULink Fixed Pay
              apabila penarikan dana dilakukan bukan dari Unit yang terbentuk
              dari Premi/Kontribusi top up tunggal maka Uang Pertanggungan
              menjadi tidak dijamin.
            </li>
            <li>
              Minimum penarikan VERSALink Capital Protection dan PRUCapital
              Protection: Rp1.000.000 dan sisa Nilai Tunai saat penarikan
              dilakukan adalah Rp15.000.000.
            </li>
            <li>
              Minimum penarikan SparkLink Assurance, PRULink Sejahtera,
              VERSALink Maxima Protection dan VERSALink Maxima Protection Plus:
              Rp1.000.000 dan sisa Nilai Tunai saat penarikan dilakukan adalah
              Rp1.000.000.
            </li>
            <li>
              Minimum penarikan PRULink Generasi Baru dan PRULink NextGen
              Rp500.000 dan sisa Nilai Tunai saat penarikan dilakukan adalah
              Rp3.000.000.
            </li>
            <li>
              Minimum penarikan PREVINALink Assurance Account Rp1.000.000/USD250
              dan sisa Nilai Tunai saat penarikan dilakukan adalah
              Rp2.000.000/USD500.
            </li>
            <li>
              Minimum penarikan PRULink Syariah Investor Account: Rp1.000.000
              dan sisa Nilai Tunai saat penarikan dilakukan adalah Rp12.000.000.
            </li>
            <li>
              Minimum penarikan PRULink Syariah Assurance Account, PRULink
              Syariah Care dan PRULink Syariah Edu Protection: Rp1.000.000 dan
              sisa Nilai Tunai saat penarikan dilakukan adalah Rp3.000.000.
            </li>
            <li>
              Minimum penarikan PRULink Syariah Generasi Baru dan PRULink
              NextGen Syariah Rp500.000 dan sisa Nilai Tunai saat penarikan
              dilakukan adalah Rp3.000.000.
            </li>
          </ul>
        </li>
        <li>
          Apabila sisa Nilai Tunai setelah dikurangi jumlah penarikan dana
          kurang dari jumlah minimum sisa Nilai Tunai, maka pengajuan ini akan
          diproses dengan jumlah Penarikan Dana (Withdrawal) dan jenis dana
          investasi sesuai dengan maksimum unit yang dapat ditarik. Oleh karena
          itu mohon Pemegang Polis memperhatikan jenis dana investasi dan jumlah
          Nilai Tunai sebelum mengajukan Penarikan Dana (Withdrawal).
        </li>
        <li>
          Pembatalan/pembentukan unit akan dianggap selesai dalam hal proses
          pembatalan/pembentukan unit telah diproses.
        </li>
        <li>
          Informasi Jenis Dana Investasi yang dapat dipilih beserta Biaya/Ujrah
          pengelolaannya dapat dilihat pada website{" "}
          <a href="https://www.prudential.co.id">www.prudential.co.id</a> atau{" "}
          <a href="https://www.prudentialsyariah.co.id">
            www.prudentialsyariah.co.id
          </a>
          .
        </li>
        <li>
          Penulisan Nominal adalah sebagai berikut:
          <ol type="a">
            <li>
              Untuk bilangan bulat ditulis dengan tanpa tanda baca. (contoh:
              1000000; dibaca Satu Juta).
            </li>
            <li>
              Untuk bilangan dengan desimal ditulis menggunakan tanda titik (.).
              (contoh: 1000000.50; dibaca Satu Juta koma Lima Puluh).
            </li>
          </ol>
          Penulisan Unit adalah sebagai berikut:
          <ol type="a">
            <li>
              Untuk bilangan bulat menggunakan tanda baca titik (.) sebagai
              penyebut ribuan. (contoh: 1.000; dibaca Seribu)
            </li>
            <li>
              Untuk bilangan dengan desimal ditambahkan tanda baca koma (,).
              (contoh: 1.000,50; dibaca Seribu koma Lima Puluh).
            </li>
          </ol>
          Sesuai ketentuan Polis, Unit ditetapkan sampai dengan pecahan 5 (lima)
          desimal.
        </li>
        <li>
          Penarikan Dana (Withdrawal) dapat menyebabkan Unit yang tersedia tidak
          mencukupi lagi untuk membayar Biaya Asuransi, Biaya/Ujrah
          Administrasi, Biaya/Ujrah Akuisisi dan Biaya/Ujrah Pemeliharaan Polis
          (untuk Polis tertentu) sehingga Polis menjadi berakhir/lapsed. Dalam
          hal tersebut, Prudential Indonesia/Prudential Syariah tidak
          bertanggung jawab atas berubahnya status Polis menjadi
          berakhir/lapsed. Mohon Pemegang Polis selalu memantau kecukupan Unit
          sehingga Pertanggungan/Kepesertaan dapat terus berjalan.
        </li>
        <li>
          Penarikan Dana (Withdrawal) dari Saldo Unit Premi/Kontribusi Berkala
          pada produk PRULink Generasi Baru/ PRULink Syariah Generasi Baru dan
          Asuransi Jiwa PRULink NextGen/ PRULink NextGen Syariah sebagaimana
          dicantumkan dalam ketentuan Polis dapat menyebabkan:
          <ol type="1">
            <li>
              Polis tidak berhak mendapatkan PRUBooster investasi selama Periode
              Ketidakberlakuan PRUBooster investasi sejak penarikan terakhir
              dilakukan.
            </li>
            <li>Polis tidak berhak atas garansi Polis Tetap Aktif.</li>
          </ol>
        </li>
        <li>
          Penarikan Dana (Withdrawal) dari Saldo Unit Premi/Kontribusi Tunggal
          pada produk VERSALink Capital Protection dan PRUCapital Protection
          sebagaimana dicantumkan dalam ketentuan Polis dapat menyebabkan Polis
          tidak berhak untuk mendapatkan tambahan Nilai Tunai yang tidak
          dijamin.
        </li>
        <li>
          Penarikan Dana (Withdrawal) dari Saldo Unit Premi/Kontribusi Berkala
          pada produk PRULink Prime Account, FORTA PLUS, VERSALink Maxima
          Account, BUILDERAssurance Account Max, SparkLink Assurance, PRULink
          Sejahtera dan PREVINALink Assurance Account sebagaimana dicantumkan
          dalam ketentuan Polis dapat menyebabkan Polis tidak berhak untuk
          mendapatkan Tambahan Alokasi Premi/Kontribusi Berkala.
        </li>
        <li>
          Produk VERSALink Maxima Protection Plus, sebagaimana dicantumkan dalam
          Polis berlaku ketentuan sebagai berikut:
          <ol type="1">
            <li>
              Penarikan dana dari Saldo Unit Premi/Kontribusi Berkala dapat
              menyebabkan Polis tidak berhak untuk mendapatkan Tambahan Alokasi
              Premi/Kontribusi Berkala.
            </li>
            <li>
              Nilai akumulasi Penarikan Dana (Withdrawal) yang melebihi 2 (dua)
              kali Premi/Kontribusi Berkala tahun pertama dapat menyebabkan
              Polis tidak berhak untuk mendapatkan Loyalty Bonus.
            </li>
          </ol>
        </li>
        <li>
          Pembayaran atas Polis dalam mata uang dolar Amerika Serikat yang
          ditransfer ke rekening dalam mata uang rupiah akan menggunakan kurs
          tengah Bank Indonesia pada tanggal pembayaran.
        </li>
        <li>
          Pembayaran yang ditujukan ke rekening bank di luar negeri wajib
          mencantumkan SWIFT Code, ABA (untuk rekening tujuan negara Amerika
          Serikat), IBAN (untuk rekening tujuan negara Eropa) dan jenis mata
          uang rekening bank tersebut, untuk WNI wajib melampirkan dokumen
          Permanent Residence dan untuk WNA wajib melampirkan KITAS yang masih
          berlaku.
        </li>
        <li>
          Apabila Pengajuan Penarikan Dana (Withdrawal) diterima bersamaan
          dengan pengajuan pengalihan unit (Switching) dan Penambahan dana (top
          up) akan berlaku ketentuan sebagai berikut:
          <h4>1. Pengajuan transaksi dilakukan pada hari yang sama</h4>
          <h5>a. Transaksi dari Fund yang berbeda</h5>
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Transaksi
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Switching
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Withdrawal
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Top Up
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Switching
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Withdrawal
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Top Up
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5>b. Transaksi dari Fund yang sama</h5>
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    rowSpan="2"
                    style={{ border: "1px solid #000", padding: "10px" }}
                  ></th>
                  <th
                    rowSpan="4"
                    style={{ border: "1px solid #000", padding: "10px" }}
                  >
                    Transaksi
                  </th>
                  <th
                    colSpan="3"
                    style={{ border: "1px solid #000", padding: "10px" }}
                  >
                    Pengajuan Pertama
                  </th>
                </tr>
                <tr>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Switching
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Withdrawal
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Top Up
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowSpan="3"
                    style={{ border: "1px solid #000", padding: "10px" }}
                  >
                    Pengajuan Selanjutnya
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Switching
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✖*
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✖*
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Withdrawal
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✖*
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔**
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Top Up
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4>2. Pengajuan transaksi dilakukan pada hari yang berbeda</h4>
          <h5>Transaksi dari Fund yang sama ataupun Fund yang berbeda</h5>
          <div style={{ overflowX: "auto" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #000",
              }}
              cellPadding="10"
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Transaksi
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Switching
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Withdrawal
                  </th>
                  <th style={{ border: "1px solid #000", padding: "10px" }}>
                    Top Up
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Switching
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Withdrawal
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    Top Up
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                  <td style={{ border: "1px solid #000", padding: "10px" }}>
                    ✔
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            * Pengajuan transaksi yang diterima selanjutnya akan diproses di
            hari berikutnya.
            <br />
            ** Pengajuan transaksi akan digabung menjadi satu proses pada hari
            yang sama.
            <br />
            X Pengajuan tidak dapat diproses pada hari yang sama.
            <br />✔ Pengajuan dapat diproses pada hari yang sama.
          </p>
        </li>

        <li>
          Jika terdapat transaksi Withdrawal dan Switching yang diajukan dari
          jenis dana investasi yang sama yang akan dihitung dengan menggunakan
          harga unit yang sama maka transaksi yang akan terproses terlebih
          dahulu adalah transaksi yang lebih dulu diterima, sementara pengajuan
          yg diajukan setelahnya akan diproses pada hari kerja berikutnya.
        </li>
        <li>
          Mohon mengisi Formulir Perubahan Data Polis Untuk Pemegang Polis
          Perorangan untuk perubahan/pendaftaran nomor rekening, apabila tidak
          ada nomor rekening yang terdaftar sesuai Ketentuan Polis maka manfaat
          Tambahan Nilai Investasi akan diproses dalam bentuk Penambahan Dana
          (top up).
        </li>
        <li>
          Formulir Elektronik yang diterima oleh Prudential Indonesia/Prudential
          Syariah adalah bersifat Final dan akan digunakan sebagai dasar
          pengajuan Penarikan Dana (Withdrawal).
        </li>
        <li>
          Dana Penarikan yang digunakan untuk Premi/Kontribusi Berkala hanya
          dapat ditujukan ke polis-polis yang ada di Prudential
          Indonesia/Prudential Syariah.
        </li>
        <li>
          Sesuai dengan Surat Edaran OJK No. 5/SEOJK.05/2022 tentang Produk
          Asuransi Yang Dikaitkan Dengan Investasi, maka untuk pengajuan
          Penarikan Dana (Withdrawal) diperlukan tambahan persyaratan berupa
          evaluasi atas kecukupan Premi/Kontribusi dan Nilai Tunai serta
          persetujuan dari Pemegang Polis atas proyeksi Nilai Tunai untuk dapat
          melanjutkan proses Penarikan Dana (Withdrawal). Hal ini dilakukan
          dalam rangka menjaga keberlangsungan Pertanggungan/Kepesertaan Polis.
        </li>
        <li>
          Penarikan Dana (Withdrawal) tidak dapat ditujukan untuk pembayaran
          Premi/Kontribusi top up tunggal atau pembayaran Premi/Kontribusi untuk
          SPAU.
        </li>
        <li>
          Pengajuan yang dilakukan pada hari libur nasional atau pada akhir
          pekan (Sabtu/Minggu) akan diproses pada hari kerja Prudential
          Indonesia/Prudential Syariah berikutnya.
        </li>
        <li>
          Harga unit dihitung saat hari bursa. Apabila bursa tutup, maka harga
          unit akan dihitung kembali pada tanggal hari bursa berikutnya.
        </li>
      </ol>
      <h3>PERNYATAAN</h3>
      <p>
        (Harap dibaca dengan teliti sebelum mengirim Formulir Elektronik)
        Pemegang Polis (selanjutnya disebut "SAYA") dengan menekan pilihan
        "Kirim" menyatakan dan menyetujui bahwa:
      </p>
      <ol>
        <li>
          Segala risiko pemilihan jenis dana investasi sepenuhnya menjadi
          tanggung jawab SAYA, dan SAYA sepenuhnya melepaskan Prudential
          Indonesia/Prudential Syariah dari segala tanggung jawab, kerugian,
          sanksi, maupun tuntutan hukum dalam bentuk apapun di kemudian hari dan
          SAYA setuju bahwa Prudential Indonesia/Prudential Syariah tidak
          memberikan jaminan dalam bentuk apapun sehubungan dengan pemilihan
          jenis dana investasi tersebut dan Prudential Indonesia/Prudential
          Syariah tidak bertanggung jawab atas segala risiko yang dapat timbul
          dari padanya.
        </li>
        <li>
          SAYA mengerti jika terdapat selisih harga unit yang terbentuk
          sehubungan dengan ketidaklengkapan pengajuan yang diterima sepenuhnya
          menjadi tanggung jawab SAYA.
        </li>
        <li>
          SAYA mengerti Prudential Indonesia/Prudential Syariah tidak
          bertanggung jawab atas hal-hal yang diakibatkan oleh kesalahan SAYA
          dalam memberikan informasi pada Formulir atau kesalahan bank dalam
          memproses transaksi termasuk namun tidak terbatas pada: i) tidak
          tepatnya penarikan dana; ii) pembayaran dana tersebut terlambat
          diterima; atau iii) pembayaran dana tersebut diterima oleh orang yang
          tidak berhak.
        </li>
        <li>
          Prudential Indonesia/Prudential Syariah dari waktu ke waktu dapat
          menggunakan data dan/atau Informasi Pribadi (termasuk namun tidak
          terbatas pada nama, alamat surat menyurat, alamat email, nomor telepon
          rumah, nomor telepon genggam dan lainnya) yang SAYA berikan dalam SPAJ
          dan dokumen lainnya sehubungan dengan pengajuan asuransi ini, termasuk
          memberikannya kepada pihak ketiga sepanjang dianggap perlu oleh
          Prudential Indonesia/Prudential Syariah dalam rangka memberikan
          pelayanan Polis Asuransi Anda.
        </li>
        <li>
          Prudential Indonesia/Prudential Syariah dapat menghubungi SAYA
          dan/atau Tertanggung/Peserta (Yang Diasuransikan), baik secara
          langsung maupun melalui Tenaga Pemasar atau pihak lain yang ditunjuk
          oleh Prudential Indonesia/Prudential Syariah, untuk menyampaikan
          informasi mengenai Polis, informasi terkait produk atau layanan
          Prudential Indonesia/Prudential Syariah termasuk informasi atas
          pengajuan Penarikan Dana (Withdrawal) yang SAYA ajukan. Dalam hal
          informasi tersebut diberikan melalui sarana komunikasi pribadi
          (termasuk Short Message Service (SMS), E-mail, dan / atau media
          komunikasi lainnya), SAYA menyetujui bahwa SMS dan atau media
          komunikasi lainnya tersebut dapat terkirim baik pada atau di luar
          hari/jam kerja.
        </li>
        <li>
          Apabila pembayaran yang SAYA lakukan terdapat indikasi mencurigakan
          sebagaimana yang tercantum pada peraturan perundang-undangan mengenai
          Pencegahan dan Pemberantasan Tindak Pidana Pencucian Uang, Pencegahan
          dan Pemberantasan Tindak Pidana Pendanaan Terorisme dan Pendanaan
          Proliferasi Senjata Pemusnah Massal beserta perubahan-perubahan dan
          peraturan pelaksanaannya, maka Prudential Indonesia/Prudential Syariah
          dapat melakukan hal-hal yang diperlukan sebagaimana tercantum di dalam
          peraturan perundang-undangan tersebut.
        </li>
        <li>
          Sesuai dengan Peraturan OJK tentang Penerapan Program Anti Pencucian
          Uang, Pencegahan Pendanaan Terorisme, dan Pencegahan Pendanaan
          Proliferasi Senjata Pemusnah Massal di Sektor Jasa Keuangan yang
          berlaku beserta peraturan pelaksanaannya, SAYA menyadari, mengerti dan
          memahami bahwa SAYA memiliki kewajiban untuk senantiasa melakukan
          pengkinian data pribadi SAYA kepada Prudential Indonesia/Prudential
          Syariah dan menyerahkan salinan dokumen pendukung yang berlaku kepada
          Prudential Indonesia/Prudential Syariah. Berdasarkan peraturan
          tersebut, SAYA juga setuju bahwa Prudential Indonesia/Prudential
          Syariah dapat menolak hubungan usaha/transaksi, membatalkan transaksi
          dan/atau Pertanggungan/Kepesertaan dengan SAYA apabila SAYA, (1) tidak
          melakukan pengkinian data tersebut; (2) diketahui dan/atau patut
          diduga menggunakan dokumen palsu; (3) menyampaikan informasi yang
          diragukan kebenarannya; (4) terdapat di dalam Daftar Terduga Teroris
          dan Organisasi Teroris; (5) terdapat di dalam Daftar Pendanaan
          Proliferasi Senjata Pemusnah Massal dan/atau; (6) memiliki sumber dana
          transaksi yang diketahui dan/atau patut diduga berasal dari hasil
          tindak pidana.
        </li>
        <li>
          Bahwa sebagai bagian dari pelaksanaan program Anti Pencucian Uang,
          Pencegahan Pendanaan Terorisme, dan Pencegahan Pendanaan Proliferasi
          Senjata Pemusnah Massal, Prudential Indonesia/Prudential Syariah juga
          harus tunduk dan patuh kepada aturan Grup Prudential yang berlaku
          terkait dengan Penerapan daftar Sanksi Tertentu. Adapun yang dimaksud
          dengan Sanksi Tertentu, adalah “tindakan pembatasan yang diberlakukan
          terhadap suatu rezim/tata pemerintahan yang berkuasa, negara,
          pemerintah, entitas, orang perorangan dan industri tertentu oleh
          lembaga international, atau pemerintah di Indonesia atau pemerintah di
          luar Indonesia, termasuk namun tidak terbatas pada, the Office of
          Financial Sanctions Implementation HM Treasury, the United Nations,
          the European Union, the US Treasury Department’s Office of Foreign
          Assets Control”.
        </li>
        <li>
          Sehubungan dengan penerapan daftar Sanksi Tertentu, apabila (i)
          Prudential Indonesia/Prudential Syariah mengetahui atau diberi tahu
          bahwa Pemegang Polis, Tertanggung/Peserta (Yang Diasuransikan),
          Pembayar Premi/Kontribusi, Pemilik Manfaat/Pengendali Perusahaan,
          orang yang mewakili (nominee), penerima manfaat, orang perorangan atau
          entitas yang terkait dengan pengajuan SPAJ ini beserta perubahannya di
          kemudian hari disebutkan atau tercantum di dalam daftar Sanksi
          Tertentu, atau (ii) jika Prudential indonesia/Prudential Syariah atau
          bank atau pihak ketiga terkait lainnya ditemukan melanggar kewajiban
          penerapan Sanksi Tertentu tersebut sebagai akibat dari pengambilan
          tindakan apa pun terhadap SPAJ ini, maka Prudential
          Indonesia/Prudential Syariah dapat mengakhiri
          Pertanggungan/Kepesertaan dengan segera, setelah memberikan
          pemberitahuan selambat-lambatnya 30 (tiga puluh) hari kalender kepada
          Pemegang Polis, dan/atau mengambil tindakan lain yang Prudential
          Indonesia/Prudential Syariah anggap perlu, termasuk namun tidak
          terbatas pada memberitahukan kepada otoritas pemerintah terkait,
          menunda transaksi, membekukan uang yang dibayarkan kepada Prudential
          Indonesia/Prudential Syariah, membekukan nilai tunai, dan/atau
          mentransfer pembayaran uang atau nilai tunai tersebut kepada otoritas
          pemerintah terkait sesuai instruksi dari otoritas pemerintah tersebut.
          Selain itu, Prudential Indonesia/Prudential Syariah dapat meminta
          penggantian atas segala kerugian yang mungkin dialami oleh Prudential
          Indonesia/Prudential Syariah kepada SAYA sebagai akibat dari
          pelaksanaan ketentuan ini, dan hak ini akan tetap berlaku walaupun
          Pertanggungan/Kepesertaan ini telah berakhir.
        </li>
        <li>
          Sehubungan dengan Peraturan Otoritas Jasa Keuangan nomor
          25/POJK.03/2019 tentang Pelaporan Informasi nasabah Asing Terkait
          Perpajakan Kepada Negara Mitra Atau Yurisdiksi Mitra dan peraturan
          pelaksananya (jika ada), dan Peraturan Menteri Keuangan nomor
          70/PMK.03/2017 sebagaimana diubah terakhir oleh Peraturan Menteri
          Keuangan nomor 19/PMK.03/2018 tentang Petunjuk Teknis Mengenai Akses
          Informasi Keuangan Untuk Kepentingan Perpajakan, dan perubahan
          selanjutnya (jika ada), SAYA sepenuhnya mengerti dan setuju dalam
          hal-hal sebagai berikut:
          <ol type="a">
            <li>
              bahwa Prudential Indonesia/Prudential Syariah diwajibkan untuk
              mempelajari dan mematuhi atau memenuhi persyaratan dari hukum,
              peraturan, perintah, dan persyaratan-persyaratan termasuk
              Persyaratan yang relevan berdasarkan Foreign Account Tax
              Compliance Act (FATCA) serta Common Reporting Standard (CRS) dan
              permintaan dari setiap badan yudisial, pajak, pemerintah dan/atau
              badan pengatur, termasuk namun tidak terbatas pada Internal
              Revenue Services (IRS) dari Amerika Serikat dan Organization for
              Economic Co-operation and Development (OECD) (selanjutnya disebut
              “Otoritas-otoritas” dan secara sendiri disebut “Otoritas”) dalam
              berbagai yurisdiksi sebagaimana diterbitkan dan diubah dari waktu
              ke waktu (selanjutnya disebut “Persyaratan yang Relevan”). Dalam
              hal ini, SAYA menyetujui seluruh langkah yang secara wajar
              dianggap perlu untuk diambil oleh Prudential Indonesia/Prudential
              Syariah untuk memastikan kepatuhan atau ketaatan Prudential
              Indonesia/Syariah dengan Persyaratan yang relevan, secara khusus
              pengungkapan atas keadaan khusus SAYA terhadap otoritas.
            </li>
            <li>
              bahwa Prudential Indonesia/Prudential Syariah dapat mengungkap
              keadaan khusus SAYA atau informasi apapun mengenai diri SAYA
              termasuk namun tidak terbatas pada informasi pajak SAYA kepada
              Otoritas sehubungan dengan ketaatan terhadap Persyaratan yang
              relevan. Pengungkapan tersebut dapat diberlakukan secara langsung
              atau dikirimkan melalui salah satu dari Kantor Pusat Prudential
              Indonesia/Prudential Syariah atau afiliasi lainnya dari Prudential
              Indonesia/Prudential Syariah. Untuk tujuan tersebut dan tanpa
              mengabaikan ketentuan manapun lainnya dalam Deklarasi atau Polis
              SAYA. Prudential Indonesia/Prudential Syariah dapat meminta SAYA
              untuk memberikan informasi lebih lanjut sebagaimana dipersyaratkan
              untuk pengungkapan kepada Otoritas manapun dan SAYA wajib
              memberikan informasi tersebut kepada Prudential
              Indonesia/Prudential Syariah dalam jangka waktu tertentu
              sebagaimana dapat dipersyaratkan secara wajar. SAYA memahami
              konsekuensi jika SAYA tidak bersedia menyampaikan pernyataan
              persetujuan, instruksi atau pemberian kuasa secara tertulis dan
              sukarela. Prudential Indonesia/Prudential Syariah berhak untuk
              menolak hubungan bisnis, transaksi dan/atau mengakhiri hubungan
              bisnis dengan SAYA, dan SAYA berhak menolak memberikan pernyataan
              persetujuan, instruksi atau pemberian kuasa secara tertulis dan
              sukarela, dengan menerima konsekuensi seperti yang disebutkan di
              atas.
            </li>
            <li>
              Tanpa mengabaikan ketentuan manapun lainnya dari Deklarasi atau
              Polis SAYA, SAYA setuju untuk menyediakan dukungan yang mungkin
              secara wajar dibutuhkan untuk memungkinkan Prudential
              Indonesia/Prudential Syariah mematuhi kewajiban Prudential
              Indonesia/Prudential Syariah berdasarkan seluruh Persyaratan yang
              relevan mengenai SAYA di Prudential Indonesia/Prudential Syariah.
            </li>
            <li>
              SAYA dengan ini setuju untuk memberikan informasi kepada
              Prudential Indonesia/Prudential Syariah secara tepat waktu atas
              setiap perubahan apapun dari keterangan yang sebelumnya telah
              disampaikan kepada Prudential Indonesia/Prudential Syariah, atau
              selambat-lambatnya 90 (sembilan puluh) hari kalender sejak
              terjadinya perubahan yang dimaksud, baik pada saat pengisian
              Formulir ini atau di waktu manapun lainnya. Secara khusus,
              merupakan hal yang sangat penting bagi SAYA untuk memberikan
              informasi kepada Prudential Indonesia/Prudential Syariah secara
              langsung apabila terdapat perubahan atas kewarganegaraan
              SAYA,status pajak atau wajib pajak atau jika SAYA menjadi wajib
              pajak di lebih dari satu negara. Jika salah satu dari perubahan
              ini terjadi atau jika informasi manapun lainnya mengindikasikan
              adanya perubahan dimaksud, Prudential Indonesia/Prudential Syariah
              dengan ini dapat meminta SAYA untuk memberikan dokumen-dokumen
              tertentu atau informasi terkait, dan SAYA setuju untuk memberikan
              informasi tersebut. Dokumen dan informasi tersebut adalah termasuk
              namun tidak terbatas pada pernyataan atau Formulir pajak (dan
              dilegalisasi oleh notaris, apabila diperlukan) yang telah
              dilengkap dan/atau ditandatangani oleh SAYA.
            </li>
            <li>
              Dalam hal SAYA tidak menyediakan informasi dan dokumen-dokumen
              sebagaimana yang dipersyaratkan oleh Prudential
              Indonesia/Prudential Syariah dalam jangka waktu tertentu atau jika
              salah satu dari informasi atau dokumen-dokumen yang disediakan
              tersebut tidak tersedia secara tepat waktu, tidak akurat atau
              tidak lengkap, maka dengan ini SAYA setuju bahwa Prudential
              Indonesia/Prudential Syariah dapat, untuk memastikan kepatuhan dan
              ketaatan yang berkelanjutan terhadap Persyaratan yang relevan,
              mengambil setiap dan seluruh langkah yang Prudential
              Indonesia/Prudential Syariah anggap sesuai untuk memastikan
              kepatuhan atau ketaatan Prudential Indonesia/Prudential Syariah
              terhadap Pernyataan yang relevan, atau mengambil langkah lainnya
              untuk melindungi kepentingan hukum dan/atau komersial Prudential
              Indonesia/Prudential Syariah.
            </li>
          </ol>
        </li>
        <li>
          {" "}
          Semua keterangan yang diberikan di dalam Formulir Elektronik ini
          adalah benar dan merupakan informasi terkini.
        </li>
        <li>
          {" "}
          SAYA mengerti apabila terjadi kegagalan pada saat melakukan transaksi
          melalui Formulir Elektronik yang disebabkan oleh gangguan koneksi
          internet, maka hal tersebut bukan merupakan tanggung jawab Prudential
          Indonesia/Prudential Syariah.
        </li>
        <li>
          {" "}
          SAYA telah membaca dan memahami seluruh Persyaratan dan Ketentuan
          Penarikan Dana(Withdrawal) yang tertera pada Formulir Elektronik ini,
          serta informasi mengenai strategi dana investasi dan Biaya/Ujrah
          Pengelolaan Dana Investasi yang SAYA pilih.
        </li>
      </ol>
      <ol
        style={{
          border: "1px solid black",
          padding: "10px",
          paddingLeft: "40px",
          backgroundColor: "lightgray",
        }}
      >
        <li>
          Pastikan data alamat surat menyurat, alamat email atau nomor handphone
          GSM yang terdaftar pada polis Anda merupakan data yang terkini dan
          valid untuk selalu dapat menerima informasi terbaru dari Prudential
          Indonesia/Prudential Syariah. Pengkinian data dapat dilakukan melalui:
          <ol>
            <li>
              Aplikasi Pulse untuk melakukan pengkinian informasi dengan mudah
            </li>
            <li>
              {" "}
              Teleprocessing dengan menghubungi Prudential Indonesia Customer
              Line di 1500085 atau Prudential Syariah Customer Line di 1500577
            </li>
            <li>
              {" "}
              Mengirimkan Formulir Perubahan Data Pemegang Polis ke Kantor Pusat
              Prudential Indonesia/Prudential Syariah.
            </li>
          </ol>
        </li>
        <li>
          Demi keamanan dan kenyamanan transaksi produk asuransi yang dikaitkan
          dengan investasi untuk Nasabah, Prudential Indonesia/Prudential
          Syariah dapat melakukan pengkinian profil risiko Nasabah apabila
          diperlukan sesuai ketentuan yang berlaku
        </li>
      </ol>
      <p
        style={{
          border: "1px solid black",
          padding: "10px",
          paddingLeft: "40px",
          backgroundColor: "lightgray",
        }}
      >
        Sehubungan dengan pengajuan Penarikan Dana (Withdrawal) kepada
        Prudential Indonesia/Prudential Syariah, dengan ini SAYA menyatakan
        bahwa: SAYA mengerti dengan pengajuan Penarikan Dana (Withdrawal) dapat
        mengurangi jumlah Nilai Tunai, dan apabila Nilai Tunai yang tersedia
        tidak lagi mencukupi untuk membayar Biaya Asuransi, Biaya/Ujrah
        Administrasi, Biaya/Ujrah Akuisisi dan Biaya/Ujrah Pemeliharaan Polis
        (untuk Polis tertentu) maka Polis akan menjadi batal/berakhir (lapsed)
        dan Pertanggungan/Kepesertaan tidak berlaku
      </p>
      <p
        style={{
          border: "1px solid black",
          padding: "10px",
          paddingLeft: "40px",
          backgroundColor: "lightgray",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          PERNYATAAN
        </span>{" "}
        Khusus untuk pilihan Dana Investasi PRULink Rupiah Extra Capital Fund
        (Harap dibaca dengan teliti sebelum mengirim Formulir Elektronik)
      </p>
      <div
        style={{
          border: "1px solid black",
          padding: "10px",
          paddingLeft: "40px",
          backgroundColor: "lightgray",
        }}
      >
        <p>
          Pemegang Polis (selanjutnya disebut “SAYA”) menyatakan telah memahami
          dan menyetujui bahwa:
        </p>
        <ol>
          <li>
            SAYA telah membaca, memahami dan menyetujui seluruh Persyaratan dan
            Ketentuan Penarikan Dana (Withdrawal) yang tertera pada formulir
            elektronik ini, serta informasi mengenai strategi dana investasi dan
            Biaya/Ujrah Pengelolaan Dana Investasi yang SAYA pilih.
          </li>
          <li>
            SAYA mengerti apabila terjadi Pengalihan Unit (Switching) dan/atau
            Penarikan Dana (Withdrawal) dari Dana Investasi PRULink Rupiah Extra
            Capital Fund, maka jaminan pengembalian Premi/Kontribusi dan
            Premi/Kontribusi top up tunggal menjadi tidak berlaku.
          </li>
          <li>
            {" "}
            SAYA mengerti apabila terjadi Penebusan Polis (Surrender) dari Polis
            yang terdapat Dana Investasi PRULink Rupiah Extra Capital Fund dan
            menyebabkan Polis berakhir sebelum Tanggal Jatuh Tempo, maka Nilai
            Tunai akan dibayarkan mengikuti ketentuan yang berlaku pada Polis
            dan jaminan pengembalian Premi dan Premi top up tunggal menjadi
            tidak berlaku.
          </li>
          <li>
            {" "}
            SAYA mengerti apabila terjadi kegagalan pada saat melakukan
            transaksi melalui formulir elektronik yang disebabkan oleh gangguan
            koneksi internet, maka hal tersebut bukan merupakan tanggung jawab
            Prudential Indonesia/Prudential Syariah.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TNC;
