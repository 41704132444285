import axios from "axios";
import { aesDecrypt } from "./useLocalStorage";

function envBaseURL() {
  if (process.env.NODE_ENV === "production")
    return "https://polisku.prudential.co.id/polisku";
  if (process.env.NODE_ENV === "development")
    return "https://polisku-uat.prudential.co.id/polisku";
  return "https://polisku-uat.prudential.co.id/polisku";
}

const baseURL = envBaseURL();

export const useAxios = async ({
  url,
  method,
  body = null,
  headers = {},
  timeout = 60000,
  override = null,
}) => {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  try {
    let header = {
      "Content-Type": "application/json",
    };

    if (user?.token) {
      header["Authorization"] = `Bearer ${user.token}`;
    }

    if (override) {
      header["Authorization"] = `Bearer ${override?.token}`;
      header.id = override?.onboardId;
      body = override?.body;
    }

    if (Object.keys(headers).length > 0) {
      Object.keys(headers).forEach((k) => {
        header[k] = headers[k];
      });
    }

    const response = await axios({
      method,
      url: override ? override?.url : baseURL + url,
      timeout: timeout,
      data: body,
      headers: header,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};
