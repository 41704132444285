import React from "react";
import { AppBar, Avatar, Box, Toolbar, Typography } from "@mui/material";
import { Instagram, Facebook, Youtube, Linkedin } from "../assets/SVGIcon";

const Footer = () => {
  return (
    <AppBar
      position="relative"
      sx={{
        backgroundColor: "black",
        top: "auto",
        bottom: 0,
        paddingBottom: "14px",
      }}
    >
      <Toolbar
        sx={(theme) => ({
          display: "flex",
          flex: 3,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingTop: "30px",
          gap: "2px",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flex: 2,
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="caption"
            sx={{ color: "gray", fontWeight: "bold" }}
          >
            PT Prudential Life Assurance dan PT Prudential Sharia Life Assurance
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "gray", fontWeight: "bold" }}
          >
            Berizin dan diawasi oleh Otoritas Jasa Keuangan
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "gray", marginTop: "20px" }}
          >
            Hak Cipta © 2020 Prudential Indonesia. All rights reserved
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            gap: "24px",
            paddingTop: "10px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              gap: "0.5px",
              paddingTop: 0,
            },
          })}
        >
          <Avatar
            onClick={() =>
              window.open(
                "https://www.facebook.com/prudential.indonesia/",
                "_blank"
              )
            }
            sx={{
              cursor: "pointer",
              padding: 0.5,
              backgroundColor: "black",
              borderColor: "#2a2a2a",
              borderWidth: "0.5px",
              borderStyle: "solid",
            }}
          >
            <Facebook fill="white" />
          </Avatar>
          <Avatar
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/prudential-indonesia-pt-prudential-life-assurance-/?trk=tabs_biz_home",
                "_blank"
              )
            }
            sx={{
              cursor: "pointer",
              padding: 0.5,
              backgroundColor: "black",
              borderColor: "#2a2a2a",
              borderWidth: "0.5px",
              borderStyle: "solid",
            }}
          >
            <Linkedin fill="white" />
          </Avatar>
          <Avatar
            onClick={() =>
              window.open(
                "https://www.youtube.com/user/PrudentialIndonesia",
                "_blank"
              )
            }
            sx={{
              cursor: "pointer",
              padding: 0.5,
              backgroundColor: "black",
              borderColor: "#2a2a2a",
              borderWidth: "0.5px",
              borderStyle: "solid",
            }}
          >
            <Youtube fill="white" />
          </Avatar>
          <Avatar
            onClick={() =>
              window.open("https://www.instagram.com/id_prudential/", "_blank")
            }
            sx={{
              cursor: "pointer",
              padding: 0.5,
              backgroundColor: "black",
              borderColor: "#2a2a2a",
              borderWidth: "0.5px",
              borderStyle: "solid",
            }}
          >
            <Instagram fill="white" />
          </Avatar>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
