import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);

  const login = (data) => {
    setUser(data);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("timerStatus");
    localStorage.removeItem("assessmentResults");
    localStorage.removeItem("resultMatchings");
    localStorage.removeItem("withdrawalForm");
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
