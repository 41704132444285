import React, { useEffect } from "react";
import { useAxios } from "../../hooks/useAxios";
function Landing() {
    useEffect(() => {
        const body={local:true};
        useAxios({
        method: "post",
        url: "/check-ekyc",
        title: "check-ekyc",
        body: body,
        
        }).then((resp)=>{
            try {
                const { status, data } = resp;
                if (status == 200) {
                    if (data.resp_code == 0) {
                        window.location.href = "/create-pin";
                    } else {
                        window.location.href = "/login";
                    }
                } else {
                    window.location.href = "/login";
                }
            } catch (error) {
                window.location.href = "/login";
            }
        })    
    }, []);
    return (
        <div style={{height:400, margin:10, marginTop:10}}>
            please wait...
        </div>
    );
}

export default Landing;