import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IconCalendar } from "../../assets/SVGIcon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Person from "../../assets/images/login.jpg";
import pruIcon from "../../assets/Icon/pru-icon.png";
import { useAuth } from "../../hooks/useAuth";
import { useAxios } from "../../hooks/useAxios";

import Language from "../../components/Language";

const SITE_KEY = "6LcddxkpAAAAAJZFSxIyuKBCeqU90lLPav8VofiI";

const LoginField = (field, isFailed) => {
  return {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& label:after": {
        paddingTop: 5,
      },
      "& fieldset": {
        borderColor: "white",
        border: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        border: "none",
      },
      "&:hover fieldset": {
        borderColor: "white",
        border: "none",
      },
      "& .MuiInputBase-input": {
        transition: "padding 0.2s",
        paddingTop: field !== "" ? 5 : "none",
      },
      "&.Mui-focused .MuiInputBase-input": {
        borderColor: "white",
        paddingTop: 5,
      },
    },
    "& label.Mui-focused": {
      fontWeight: "bold",
      color: isFailed ? "#F04E1B" : "black",
      fontSize: 18,
      paddingTop: 5,
    },
    "& .MuiInputLabel-shrink": {
      fontWeight: "bold",
      color: isFailed ? "#F04E1B" : "black",
      fontSize: 18,
      paddingTop: 5,
    },
  };
};

export const Login = () => {
  const language = window.localStorage.getItem("language") || "id";
  const { echo } = Language();
  let deviceID = window.localStorage.getItem("deviceID");
  const clientWidth = document.documentElement.clientWidth;
  const navigate = useNavigate();
  const { login } = useAuth();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [noPolis, setNoPolis] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {}
    );
  }, []);

  function handleChangeNoPolis(e) {
    setIsFailed(false);
    setErrorMessage("");
    setNoPolis(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isNaN(noPolis)) {
      setLoading(false);
      setIsFailed(true);
      setErrorMessage(echo("invalid-data"));
      return;
    }
    setIsFailed(false);
    setLoading(true);
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (!deviceID || deviceID == "null") {
      deviceID = `${userAgent}-${platform}`;
      window.localStorage.setItem("deviceID", deviceID);
    }
    const captcha = await grecaptcha.execute(SITE_KEY, { action: "submit" });
    try {
      const dob = formatDate(dateOfBirth);
      useAxios({
        method: "post",
        url: "/login",
        body: {
          cust_no: noPolis,
          dob,
          device_id: deviceID,
          captcha,
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          const userData = {
            token: data?.token,
            custNo: noPolis,
            policyNumber: noPolis,
            dob,
            email: data?.email,
            phone: data?.phone,
          };
          if (status == 200) {
            setLoading(false);
            if (data?.resp_code == 0) {
              login(userData);
              navigate("/pin");
            } else if (data?.resp_code == 5) {
              login(userData);
              if (data?.onboard_url) window.location.href = data?.onboard_url;
              else {
                setLoading(false);
                setIsFailed(true);
                setErrorMessage(echo("invalid-data"));
              }
            } else if (data?.resp_code == 7) {
              login(userData);
              navigate("/create-pin");
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc.Indonesian
                  : data.resp_desc.English;
              setIsFailed(true);
              setErrorMessage(responseError || echo("something-went-wrong"));
            }
          } else {
            const responseError =
              language === "id"
                ? response?.data?.resp_desc?.Indonesian
                : response?.data?.resp_desc?.English;
            setIsFailed(true);
            setErrorMessage(responseError || echo("something-went-wrong"));
          }
          setLoading(false);
        }
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }
  return (
    <Container maxWidth={false} disableGutters={true} minWidth="sm">
      <Box
        sx={(theme) => ({
          flex: 1,
          backgroundColor: "#f7f7f7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingBottom: "10vh",
          [theme.breakpoints.up("lg")]: {
            flex: 1,
            backgroundColor: "#f7f7f7",
            alignItems: "center",
            display: "flex",
            paddingTop: 10,
            height: "100vh",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            [theme.breakpoints.up("lg")]: {
              flex: 3,
              flexDirection: "row",
              justifyContent: "center",
              margin: 10,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              [theme.breakpoints.up("lg")]: {
                flex: 2,
                alignItems: "flex-start",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                marginY: "20px",
                lineHeight: "32px",
                [theme.breakpoints.down("lg")]: {
                  alignItems: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginTop: "45px",
                  width: "50vw",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "85vw",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  fontWeight: 700,
                  fontSize: "40px",
                  fontFamily: "Open Sans-Regular, Helvetica",
                  lineHeight: "32px",
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  [theme.breakpoints.up("lg")]: {
                    fontSize: "38px",
                    lineHeight: "48px",
                    marginBottom: "0px",
                    "& span": {
                      fontWeight: 700,
                      color: "",
                    },
                  },
                  [theme.breakpoints.down("lg")]: {
                    "& span": {
                      fontWeight: "lighter",
                      color: "#ed1a2d",
                    },
                  },
                })}
                variant="h4"
              >
                Selamat
                <span> Datang</span>
                <Typography
                  sx={(theme) => ({
                    fontWeight: 700,
                    fontSize: "40px",
                    fontFamily: "Open Sans-Regular, Helvetica",
                    lineHeight: "32px",
                    marginBottom: "10px",
                    [theme.breakpoints.up("lg")]: {
                      fontSize: "38px",
                      lineHeight: "48px",
                      marginBottom: "0px",
                    },
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  di
                </Typography>
              </Box>
              <Typography
                sx={(theme) => ({
                  fontSize: "40px",
                  color: "#ed1a2d",
                  fontWeight: "lighter",
                  fontFamily: "Open Sans-Regular, Helvetica",
                  lineHeight: "32px",
                  marginBottom: "10px",
                  marginTop: "5px",
                  [theme.breakpoints.up("lg")]: {
                    fontSize: "38px",
                    lineHeight: "48px",
                  },
                  [theme.breakpoints.down("lg")]: {
                    display: "none",
                  },
                })}
                variant="h4"
              >
                Polisku
              </Typography>
              <Typography
                sx={(theme) => ({
                  marginBottom: "-30px",
                  width: 450,
                  [theme.breakpoints.down("lg")]: {
                    width: "50vw",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                  },
                })}
              >
                {echo("easy-step")}
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "32px",
                [theme.breakpoints.down("lg")]: {
                  alignItems: "center",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  marginTop: "30px",
                  marginBottom: "15px",
                })}
              >
                <Box
                  sx={(theme) => ({
                    backgroundColor: "#ffffff",
                    border: isFailed ? "1px solid #F04E1B" : "none",
                    borderRadius: "8px",
                    padding: "8px",
                    boxShadow: "0 4px 24px -2px #D3d3d3",
                    width: 450,
                    [theme.breakpoints.down("sm")]: {
                      width: "80vw",
                    },
                  })}
                >
                  <TextField
                    variant="outlined"
                    label={echo("policy-no")}
                    placeholder={echo("policy-no")}
                    value={noPolis}
                    inputProps={{
                      maxLength: 8,
                    }}
                    onChange={handleChangeNoPolis}
                    sx={() => LoginField(noPolis, isFailed)}
                  />
                </Box>
                <Box
                  sx={(theme) => ({
                    backgroundColor: "#ffffff",
                    border: isFailed ? "1px solid #F04E1B" : "none",
                    borderRadius: "8px",
                    padding: "8px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    boxShadow: "0 4px 24px -2px #D3d3d3",
                    justifyContent: "space-between",
                    width: 450,
                    [theme.breakpoints.down("sm")]: {
                      width: "80vw",
                    },
                  })}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label={echo("dob")}
                      placeholder={echo("dob")}
                      open={openCalendar}
                      onOpen={() => setOpenCalendar(true)}
                      onClose={() => setOpenCalendar(false)}
                      disableFuture
                      onChange={(value) => {
                        setIsFailed(false);
                        setErrorMessage("");
                        setDateOfBirth(value);
                      }}
                      sx={() => LoginField(dateOfBirth, isFailed)}
                      variant="outlined"
                      slots={{
                        openPickerIcon: () => null,
                      }}
                    />
                  </LocalizationProvider>
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      border: 0,
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    onClick={() => setOpenCalendar(true)}
                  >
                    <IconCalendar onClick={() => setOpenCalendar(true)} />
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={(theme) => ({
                  display: isFailed ? "block" : "none",
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  fontWeight: 300,
                  lineHeight: "22px",
                  marginTop: -1,
                  color: "#F04E1B",
                  [theme.breakpoints.down("lg")]: {
                    width: "50vw",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                  },
                })}
              >
                {errorMessage}
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontFamily: "Helvetica",
                  fontSize: "12px",
                  color: "grey",
                  fontWeight: 300,
                  "& span": {
                    color: "red",
                    cursor: "pointer",
                  },
                  [theme.breakpoints.down("lg")]: {
                    width: "50vw",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                  },
                })}
              >
                Dengan masuk atau membuat akun kamu setuju dengan{" "}
                <span onClick={() => navigate("/terms-and-conditions")}>
                  Syarat & Ketentuan
                </span>{" "}
                serta untuk menggunakan layanan ini.
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    disabled={noPolis === "" || dateOfBirth === ""}
                    type="submit"
                    sx={(theme) => ({
                      fontStyle: "normal",
                      fontFamily: "Open Sans-Bold, Helvetica",
                      textTransform: "none",
                      display: "flex",
                      alignItems: "flex-start",
                      alignSelf: "flex-start",
                      gap: "10px",
                      justifyContent: "center",
                      padding: "16px 24px",
                      width: "180px",
                      cursor: "pointer",
                      borderColor: "#f7f7f7",
                      backgroundColor:
                        noPolis === "" || dateOfBirth === ""
                          ? "#D8D8D8"
                          : "#ed1a2d",
                      borderRadius: "100px",
                      [theme.breakpoints.down("lg")]: {
                        width: "100%",
                      },
                      "&:hover": {
                        backgroundColor: "#ed1a2d",
                        boxShadow: "0 12px 8px -2px #D3d3d3",
                        [theme.breakpoints.down("lg")]: {
                          width: "100%",
                        },
                      },
                    })}
                  >
                    <Typography
                      sx={{
                        color:
                          noPolis === "" || dateOfBirth === ""
                            ? "#808080"
                            : "#ffffff",
                        fontFamily: "Open Sans-Bold, Helvetica",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      {echo("login")}
                    </Typography>
                  </Button>
                  <Typography
                    sx={(theme) => ({
                      fontFamily: "Helvetica",
                      fontSize: "12px",
                      color: "grey",
                      fontWeight: 300,
                      [theme.breakpoints.down("lg")]: {
                        width: "50vw",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "80vw",
                      },
                    })}
                  >
                    Apabila anda membutuhkan bantuan, silakan menghubungi
                    Customer Line 1500085 (PLA) / 1500577 (PSLA) atau email ke{" "}
                    <span style={{ color: "#ed1a2d" }}>
                      customer.idn@prudential.co.id
                    </span>{" "}
                    (PLA) /{" "}
                    <span style={{ color: "#ed1a2d" }}>
                      customer.idn@prudentialsyariah.co.id
                    </span>{" "}
                    (PSLA).
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "flex-end",
              height: "32vh",
              width: "620px",
              backgroundColor: "#e5eaef",
              display: "hidden",
              marginRight: "-500px",
              marginTop: "35px",
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            })}
          />
          <Box
            sx={(theme) => ({
              flex: 1,
              marginTop: "70px",
              display: {
                xs: "none",
                lg: "block",
              },
            })}
          >
            <img
              height="400px"
              width="500px"
              style={{
                width: "500px",
                height: "400px",
                objectFit: "cover",
              }}
              src={Person}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default Login;
